import { ECareInternalNotes } from 'api/eCareInternalNotes/eCareInternalNotes.types';
import ECareAlarmInternalNotes from 'components/UI/molecules/ECareAlarmForms/ECareAlarmInternalNotes/ECareAlarmInternalNotes';
import { PartialRecord } from 'constants/_types/PartialRecord';
import { AcceptedCellValues } from 'hooks/useTableData/useTableData';

const usePhoneCallNotes = (eCareInternalNotesData: PartialRecord<keyof ECareInternalNotes, AcceptedCellValues>[] | null) => {
  const internalNote = eCareInternalNotesData && eCareInternalNotesData[0];

  return [
    {
      label: '',
      value: internalNote && <ECareAlarmInternalNotes internalNote={internalNote as ECareInternalNotes} />,
    },
  ];
};

export default usePhoneCallNotes;
