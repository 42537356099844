import { FC, useMemo } from 'react';

import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import eCareCusComments from 'api/eCareCusComments/eCareCusComments';
import { ProfessionalECareCusCommentsEntity } from 'api/eCareCusComments/eCareCusComments.types';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import BoxWithHeader from 'components/UI/molecules/BoxWithHeader/BoxWithHeader';
import SectionWithTitle from 'components/UI/molecules/SectionWithTitle/SectionWithTitle';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import generalMessages from 'translations/common/general.mjs';
import proECareOrderMessages from 'translations/specific/pro_ecare_order.mjs';

import CusCommentList from './_components/CusCommentList/CusCommentList';
import useStyles from './ECareCusComments.styles';

type FormInput = {
  content: string;
  order_id: string;
  service_recipient: string;
};

type Props = {
  recipientId: string;
};

const ECareCusComments: FC<Props> = ({ recipientId }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { orderId } = useParams();
  const { showConfirmationDialog } = useConfirmationDialogContext();

  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_CUS_COMMENTS, orderId], [orderId]);
  const { data: commentData } = useQuery(queryKey, eCareCusComments.getAllComments(orderId as string), {
    refetchOnWindowFocus: false,
  });

  const queryClient = useQueryClient();
  const statusMutation = useMutation(eCareCusComments.createComment(), {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
  });

  const { control, getValues, reset } = useForm<FormInput>();

  const addNewComment = async () => {
    if (!orderId) return;
    const confirmed = await showConfirmationDialog({
      title: t(proECareOrderMessages.cusComments.addComment),
      body: <FormInputText control={control} label='' multiline name='content' rows={6} />,
      buttonLabels: { onTrue: t(generalMessages.save), onFalse: t(generalMessages.cancel) },
    });
    if (confirmed) {
      await statusMutation.mutateAsync({
        order: orderId,
        content: getValues('content'),
        service_recipient: recipientId,
      });
    }
    // reset form
    reset();
  };

  const showCommentHistory = () => {
    showConfirmationDialog({
      title: t(proECareOrderMessages.cusComments.title),
      body:
        commentData?.data &&
        commentData.data.map(note => (
          <BoxWithHeader
            className={classes.boxList}
            description={note.content as string}
            header={[
              {
                label: t(proECareOrderMessages.cusComments.updateDate),
                value: note?.updatedAt ? unknownDateToString(note.updatedAt as Date, DATE_FORMATS.DISPLAY) : t(generalMessages.noData),
              },
            ]}
            key={note.id as string}
          />
        )),
      infoOnly: true,
      buttonLabels: { onTrue: t(generalMessages.save) },
    });
  };

  return (
    <SectionWithTitle
      className={classes.section}
      title={t(proECareOrderMessages.cusComments.title)}
      titleIcon={
        <Button variant='contained' onClick={addNewComment}>
          {t(proECareOrderMessages.cusComments.addComment)}
        </Button>
      }
      titleRightSideComponent={
        commentData &&
        commentData.data.length > 0 && (
          <Button variant='outlined' onClick={showCommentHistory}>
            {t(proECareOrderMessages.cusComments.moreComment)}
          </Button>
        )
      }
    >
      {commentData && <CusCommentList data={commentData.data as ProfessionalECareCusCommentsEntity[]} />}
    </SectionWithTitle>
  );
};

export default ECareCusComments;
