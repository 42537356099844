import { TranslationKey } from 'constants/_types/TranslationKey';
import ecarePhoneCallMessages from 'translations/specific/ecarePhoneCall.mjs';

import { InternalNotesDTO } from '../eCareAlarms/eCareAlarms.types';
import { PatientDTO, PatientFE } from '../patient/patient.types';

export type Caller = 'ODBIORCA_USLUGI' | 'PRACOWNIK_TELEOPIEKI';

export const eCarePhoneCallStatus = ['NIEODEBRANE', 'ODEBRANE', 'PORZUCONE', 'WYKONANE'] as const;
export type PhoneCallStatus = typeof eCarePhoneCallStatus[number] | null;

export const eCarePhoneCallType = ['PRZYCHODZACE', 'WYCHODZACE'];
export type PhoneCallType = typeof eCarePhoneCallType[number];

export type Operator = {
  first_name: string;
  last_name: string;
  phone_number?: string;
  email?: string;
};

export type EcarePhoneCallDTO = {
  id: string;
  internal_id: string;
  created_at: string;
  updated_at: string;
  call_date: string;
  call_received_date: string;
  call_end_date: string;
  service_recipient_phone_number: string;
  internal_notes: InternalNotesDTO;
  caller: Caller;
  status: PhoneCallStatus;
  call_type: PhoneCallType;
  call_summary: string;
  order: string;
  payer: string;
  subscription: string;
  service_recipient: string;
  patient: PatientDTO | null;
  operator: Operator;
  last_sync: string | null;
};

export type EcarePhoneCall = {
  id: string;
  internalId: string;
  createdAt: Date;
  updatedAt: Date;
  callDate: Date;
  callReceivedDate: Date;
  callEndDate: Date;
  callType: PhoneCallType;
  internalNotes: InternalNotesDTO;
  serviceRecipientPhoneNumber: string;
  caller: Caller;
  status: PhoneCallStatus;
  callSummary: string;
  order: string;
  payer: string;
  subscription: string;
  serviceRecipient: string;
  patient: PatientFE | null;
  operator: Operator;
  lastSync: Date | null;
};

export type ECarePhoneCallUnassigned = {
  status?: PhoneCallStatus;
  call_type?: PhoneCallType;
  call_summary?: string;
};

export const eCarePhoneCallStatusDictionary: Record<Exclude<PhoneCallStatus, null>, TranslationKey> = {
  NIEODEBRANE: ecarePhoneCallMessages.status.unanswered,
  ODEBRANE: ecarePhoneCallMessages.status.answered,
  PORZUCONE: ecarePhoneCallMessages.status.abandoned,
  WYKONANE: ecarePhoneCallMessages.status.finished,
};

export const eCarePhoneCallCallTypeDictionary: Record<PhoneCallType, TranslationKey> = {
  PRZYCHODZACE: ecarePhoneCallMessages.callType.incoming,
  WYCHODZACE: ecarePhoneCallMessages.callType.outgoing,
};

export type EcarePhoneCallListFilters =
  | 'internal_id'
  | 'status'
  | 'subscription'
  | 'call_date'
  | 'service_recipient__patient__personal_data__first_name'
  | 'service_recipient__patient__personal_data__last_name'
  | 'service_recipient__patient__pesel'
  | 'call_type'
  | 'payer'
  | 'service_recipient__patient__personal_data__main_address__street_name'
  | 'service_recipient__patient__personal_data__main_address__city'
  | 'service_recipient__patient__personal_data__main_address__postcode'
  | 'service_recipient__patient__project_user_number'
  | 'operator__last_name';
