import ECarePhoneCallIncomingCallDropdown from 'components/UI/molecules/ECarePhoneCallIncomingCallDropdown/ECarePhoneCallIncomingCallDropdown';
import ECarePhoneCallStatusDropdown from 'components/UI/molecules/ECarePhoneCallStatusDropdown/ECarePhoneCallStatusDropdown';

const useStatusDropdown = (phoneCallId: string) => [
  {
    label: '',
    value: <ECarePhoneCallStatusDropdown phoneCallId={phoneCallId} />,
  },
  {
    label: '',
    value: <ECarePhoneCallIncomingCallDropdown phoneCallId={phoneCallId} />,
  },
];

export default useStatusDropdown;
