import { FC, useCallback, useMemo } from 'react';

import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import eCareCusComments from 'api/eCareCusComments/eCareCusComments';
import { ProfessionalECareCusCommentsEntity } from 'api/eCareCusComments/eCareCusComments.types';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import BoxWithHeader from 'components/UI/molecules/BoxWithHeader/BoxWithHeader';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import generalMessages from 'translations/common/general.mjs';
import proECareOrderMessages from 'translations/specific/pro_ecare_order.mjs';

import useStyles from './ECareCusCommentsHistory.styles';

type FormInput = {
  content: string;
};

type Props = {
  comment: ProfessionalECareCusCommentsEntity;
};

const ECareCusCommentsHistory: FC<Props> = ({ comment }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { showConfirmationDialog } = useConfirmationDialogContext();

  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_CUS_COMMENTS, comment.order], [comment.order]);
  const queryClient = useQueryClient();
  const statusMutation = useMutation(eCareCusComments.patchCommentData(comment.id), {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
  });

  const { control, getValues } = useForm<FormInput>();

  const updateNote = useCallback(async () => {
    const confirmed = await showConfirmationDialog({
      title: t(proECareOrderMessages.cusComments.editComment),
      body: <FormInputText control={control} defaultValue={comment.content} label='' multiline name='content' rows={6} />,
      buttonLabels: { onTrue: t(generalMessages.save), onFalse: t(generalMessages.cancel) },
    });
    if (confirmed)
      statusMutation.mutate({
        content: getValues('content'),
      });
  }, [comment.content]);

  const header = [
    {
      label: t(proECareOrderMessages.cusComments.updateDate),
      value: comment.updatedAt ? unknownDateToString(comment.updatedAt) : t(generalMessages.noData),
    },
  ];

  return (
    <BoxWithHeader
      description={comment.content}
      header={header}
      headerElement={
        comment.lastSync ? undefined : (
          <Button className={classes.button} size='small' variant='outlined' onClick={updateNote}>
            {t(proECareOrderMessages.cusComments.edit)}
          </Button>
        )
      }
    />
  );
};

export default ECareCusCommentsHistory;
