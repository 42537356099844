import type { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import patients from 'api/patient/patients';
import CancelButton from 'components/UI/molecules/BottomFixedContainer/_components/CancelButton/CancelButton';
import DefinitionList from 'components/UI/molecules/DefinitionList/DefinitionList';
import DialogWrapper from 'components/UI/molecules/DialogWrapper/DialogWrapper';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import generalMessages from 'translations/common/general.mjs';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

type Props = {
  isOpen: boolean;
  close: () => void;
  patientId: number;
};

const ProfessionalAlarmKeyBoxDialog: FC<Props> = ({ isOpen, close, patientId }) => {
  const { t } = useTranslation();

  const { data } = useQuery([QUERY_KEYS.PATIENT_INFO, patientId], patients.getPatient(patientId, true));

  // TODO: this is only example, BE not ready yet
  const keyBoxDetails = [
    {
      label: 'Kod klucza',
      value: 'mjhgtr34567uyhbn',
    },
  ];

  return (
    <DialogWrapper
      close={close}
      customActions={<CancelButton action={close} label={t(generalMessages.close)} />}
      dialogProps={{
        maxWidth: 'sm',
      }}
      header={t(proECareAlarmMessages.alarmCard.dialogs.keyBox)}
      isOpen={isOpen}
    >
      <DefinitionList columns={1} definitions={keyBoxDetails} />
    </DialogWrapper>
  );
};

export default ProfessionalAlarmKeyBoxDialog;
