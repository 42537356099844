import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  noPadding: {
    padding: 0,
  },
  section: {
    gap: 0,
  },
  boxList: {
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
