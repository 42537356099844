import { ECareCusComments, ECareCusCommentsDTO } from './eCareCusComments.types';

export const parseSingleECareCusCommentsForFE = (eCareCusCommentsDTO: ECareCusCommentsDTO): ECareCusComments => ({
  id: eCareCusCommentsDTO.id,
  createdAt: new Date(eCareCusCommentsDTO.created_at),
  updatedAt: new Date(eCareCusCommentsDTO.updated_at),
  lastSync: eCareCusCommentsDTO.last_sync ? new Date(eCareCusCommentsDTO.last_sync) : null,
  date: new Date(eCareCusCommentsDTO.date),
  content: eCareCusCommentsDTO.content,
  order: eCareCusCommentsDTO.order,
  serviceRecipient: eCareCusCommentsDTO.service_recipient,
});

export const parseECareCusCommentsListForFE = (eCareCusCommentsDTO: ECareCusCommentsDTO[]): ECareCusComments[] =>
  eCareCusCommentsDTO.map(parseSingleECareCusCommentsForFE);
