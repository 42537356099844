export const userPermissionsPossibleValues = [
  'view_patient',
  'change_patient', // change_patient probably cen be removed

  // ecareserviceorder
  'view_ecareserviceorder',

  // ecaresos
  'view_ecaresos',

  // ecaretechnicalproblem
  'view_ecaretechnicalproblem',

  // actionlogentry
  'view_actionlogentry',

  // contenttype
  'view_contenttype',

  // mdtcoreservice
  'view_mdtcoreservice',

  // personaldata
  'change_personaldata',

  // addressdata
  'change_addressdata',

  // avatar
  'change_avatar',

  // note
  'view_note',
  'add_note',

  // phonecall
  'view_phonecall',
  'change_phonecall',
] as const;

export type UserPermission = typeof userPermissionsPossibleValues[number];
