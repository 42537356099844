import axios from 'axios';

import { DefaultApiResponse, PaginatedApiResponse } from 'api/_types';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { PartialRecord } from '../../constants/_types/PartialRecord';
import { TableDataBaseParamsForQuery } from '../../constants/_types/TableDataBaseParamsForQuery';
import { TableDataQueryFunctionParams } from '../../constants/_types/TableDataQueryFunction';
import { TableDataQueryResult } from '../../constants/_types/TableDataQueryResult';
import parseFiltersForBE from '../_commonParsers/parseFiltersForBE';
import parseSortForBE from '../_commonParsers/parseSortForBE';
import { parseECareOrderListForFE, parseSingleECareOrderForFE } from './eCareOrder.parsers';
import { ECareOrder, ECareOrderDTO, ECareOrderListFilters, ECareOrderUnassigned } from './eCareOrder.types';

const endpoint = '/api/v1/ecare-order/';

type GetECareOrderListQuery = () => Promise<ECareOrder[]>;
export type UpdateECareOrderInput = ECareOrderUnassigned;

type GetECareOrdersQuery = (params: TableDataQueryFunctionParams<keyof ECareOrder>) => Promise<TableDataQueryResult<keyof ECareOrder>>;
type GetECareOrderQuery = () => Promise<ECareOrder>;
type GetECareOrderForPatientQuery = () => Promise<ECareOrder>;
type UpdateECareOrderQuery = (data: UpdateECareOrderInput) => Promise<DefaultApiResponse<ECareOrder>>;

const eCareOrder = {
  getAllOrders: (): GetECareOrdersQuery => async params => {
    const paramsForBE: TableDataBaseParamsForQuery & PartialRecord<ECareOrderListFilters, string> = {
      limit: params.limit,
      offset: params.offset,
      ordering: parseSortForBE(params.sort),
      ...parseFiltersForBE<ECareOrderListFilters>(params.filters),
    };

    const { data } = await axios.get<GetECareOrdersQuery, PaginatedApiResponse<ECareOrderDTO>>(
      createUrlWithParams({
        url: endpoint,
        params: paramsForBE,
        removeEmptyParams: true,
      }),
    );
    return { data: parseECareOrderListForFE(data.results), count: data.count };
  },
  getECareOrder:
    (id: string | number): GetECareOrderQuery =>
    async () => {
      const { data } = await axios.get<GetECareOrderQuery, DefaultApiResponse<ECareOrderDTO>>(`${endpoint}${id}/`);

      return parseSingleECareOrderForFE(data);
    },
  getECareOrderForPatient:
    ({ patientId }: { patientId: number }): GetECareOrderForPatientQuery =>
    async () => {
      // SORT from newest to oldest and return the first one
      const { data } = await axios.get<GetECareOrderListQuery, PaginatedApiResponse<ECareOrderDTO>>(
        // TODO pro-patient-page: ensure it works
        createUrlWithParams({ url: endpoint, params: { patient: patientId, ordering: '-updated_at' } }),
      );
      return parseECareOrderListForFE(data.results)[0];
    },
  patchECareOrderData:
    (id: string): UpdateECareOrderQuery =>
    data =>
      axios.patch<UpdateECareOrderQuery, DefaultApiResponse<ECareOrder>>(`${endpoint}${id}/`, data),
  setStatusECareOrderData:
    (id: string): UpdateECareOrderQuery =>
    data =>
      axios.post<UpdateECareOrderQuery, DefaultApiResponse<ECareOrder>>(`${endpoint}${id}/set-status/`, data),
};
export default eCareOrder;
