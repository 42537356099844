import { FC, useMemo } from 'react';

import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ECareAlarm } from 'api/eCareAlarms/eCareAlarms.types';
import eCareContactNotes from 'api/eCareContactNotes/eCareContactNotes';
import { ECareContactNote } from 'api/eCareContactNotes/eCareContactNotes.types';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import InfoIconWithTooltip from 'components/UI/atoms/InfoIconWithTooltip/InfoIconWithTooltip';
import BoxWithHeader from 'components/UI/molecules/BoxWithHeader/BoxWithHeader';
import DefinitionList from 'components/UI/molecules/DefinitionList/DefinitionList';
import SectionWithTitle from 'components/UI/molecules/SectionWithTitle/SectionWithTitle';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import generalMessages from 'translations/common/general.mjs';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

import useAlarmNotesTable from '../../_hooks/useAlarmNotesTable/useAlarmNotesTable';
import usePhoneCallNotes from './_hooks/usePhoneCallNotes/usePhoneCallNotes';
import useStyles from './PartnerNotes.styles';

type FormInput = {
  content: string;
};

type Props = {
  eCareAlarmsData: ECareAlarm;
  disabled?: boolean;
};

const PartnerNotes: FC<Props> = ({ eCareAlarmsData, disabled }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { showConfirmationDialog } = useConfirmationDialogContext();
  const orderId = eCareAlarmsData.order;

  const queryClient = useQueryClient();
  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_INTERNAL_NOTES, orderId], [orderId]);
  const { data: contactNotesData } = useQuery(queryKey, eCareContactNotes.getAllNotes(orderId), {
    refetchOnWindowFocus: false,
  });

  const statusMutation = useMutation(eCareContactNotes.createNote(), {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
  });

  const { control, getValues, reset } = useForm<FormInput>();

  const addNewNote = async () => {
    const confirmed = await showConfirmationDialog({
      title: t(proECareAlarmMessages.interventionAlarmPartnerTitle),
      body: <FormInputText control={control} label='' multiline name='content' rows={6} />,
      buttonLabels: { onTrue: t(generalMessages.save), onFalse: t(generalMessages.cancel) },
    });
    if (confirmed) {
      await statusMutation.mutateAsync({
        content: getValues('content'),
        service_recipient_id: eCareAlarmsData.serviceRecipient,
        order: orderId,
      });
    }

    // reset form
    reset();
  };

  const alarmsNotesTable = useAlarmNotesTable(contactNotesData?.data as ECareContactNote[]);

  const showNotesHistory = () => {
    showConfirmationDialog({
      title: t(proECareAlarmMessages.interventionAlarmPartnerNotes),
      body:
        alarmsNotesTable?.data &&
        alarmsNotesTable.data.map(note => (
          <BoxWithHeader
            className={classes.boxList}
            description={note.content}
            header={[
              {
                label: t(proECareAlarmMessages.eCareAlarmsPartnerNotesTable.updatedTime),
                value: note?.updatedAt ? unknownDateToString(note.updatedAt, DATE_FORMATS.DISPLAY) : t(generalMessages.noData),
              },
            ]}
            key={note.id}
          />
        )),
      infoOnly: true,
      buttonLabels: { onTrue: t(generalMessages.save) },
    });
  };

  const phoneCallNote = usePhoneCallNotes(alarmsNotesTable?.data, disabled);

  return (
    <SectionWithTitle
      className={classes.section}
      title={t(proECareAlarmMessages.interventionAlarmPartnerDescription)}
      titleIcon={
        <>
          <Button disabled={disabled} variant='contained' onClick={addNewNote}>
            {t(proECareAlarmMessages.alarmCard.buttons.addNote)}
          </Button>
          <InfoIconWithTooltip content={t(proECareAlarmMessages.bitrix.tooltip.addNote)} />
        </>
      }
      titleRightSideComponent={
        phoneCallNote &&
        phoneCallNote.length > 0 && (
          <Button variant='outlined' onClick={showNotesHistory}>
            {t(proECareAlarmMessages.interventionAlarmPartnerMore)}
          </Button>
        )
      }
    >
      <DefinitionList
        columns={1}
        customClasses={{
          listItemClass: classes.noPadding,
        }}
        definitions={phoneCallNote}
      />
    </SectionWithTitle>
  );
};

export default PartnerNotes;
