import { FC, useCallback, useMemo } from 'react';

import { Button } from '@mui/material';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import eCareContactNotes from 'api/eCareContactNotes/eCareContactNotes';
import { ECareInternalNotes } from 'api/eCareInternalNotes/eCareInternalNotes.types';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import BoxWithHeader from 'components/UI/molecules/BoxWithHeader/BoxWithHeader';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import generalMessages from 'translations/common/general.mjs';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

import useStyles from './ECareAlarmInternalNotes.styles';

type FormInput = {
  content: string;
};

type Props = {
  internalNote: ECareInternalNotes;
  disabled?: boolean;
};

const ECareAlarmInternalNotes: FC<Props> = ({ internalNote, disabled }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { showConfirmationDialog } = useConfirmationDialogContext();

  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_INTERNAL_NOTES, internalNote.order], [internalNote.order]);

  const queryClient = useQueryClient();
  const statusMutation = useMutation(eCareContactNotes.patchNoteData(internalNote.id), {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
  });

  const { control, getValues } = useForm<FormInput>();

  const updateNote = useCallback(async () => {
    const confirmed = await showConfirmationDialog({
      title: t(proECareAlarmMessages.interventionAlarmPartnerEditTitle),
      body: <FormInputText control={control} defaultValue={internalNote.content} label='' multiline name='content' rows={6} />,
      buttonLabels: { onTrue: t(generalMessages.save), onFalse: t(generalMessages.cancel) },
    });
    if (confirmed)
      statusMutation.mutate({
        content: getValues('content'),
      });
  }, [internalNote.content]);

  const header = [
    {
      label: t(proECareAlarmMessages.eCareAlarmsPartnerNotesTable.updatedTime),
      value: internalNote?.updatedAt ? format(internalNote.updatedAt, DATE_FORMATS.DISPLAY) : t(generalMessages.noData),
    },
  ];

  return (
    <BoxWithHeader
      description={internalNote.content}
      header={header}
      headerElement={
        internalNote.lastSync ? undefined : (
          <Button className={classes.button} disabled={disabled} size='small' variant='outlined' onClick={updateNote}>
            {t(proECareAlarmMessages.edit)}
          </Button>
        )
      }
    />
  );
};

export default ECareAlarmInternalNotes;
