import { memo, useMemo } from 'react';

import { Search as DetailIcon } from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useLocation } from 'react-router-dom';

import eCareOrder from 'api/eCareOrder/eCareOrder';
import { ECareOrder, ECareOrderListFilters } from 'api/eCareOrder/eCareOrder.types';
import { ReactComponent as ECareOrderListIcon } from 'assets/icons/hub/active/cashier.svg';
import InfoIconWithTooltip from 'components/UI/atoms/InfoIconWithTooltip/InfoIconWithTooltip';
import PageTitleWithFiltersForTables from 'components/UI/molecules/PageTitleWithFiltersForTables/PageTitleWithFiltersForTables';
import type { AvailableFilter } from 'constants/_types/AvailableFilter';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import PATHS from 'constants/router/PATHS';
import getAgeFromDOB from 'helpers/getAgeFromDOB/getAgeFromDOB';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import useECareOrderStatus from 'hooks/useECareOrderStatus/useECareOrderStatus';
import useTableData from 'hooks/useTableData/useTableData';
import exhaustiveGuard from 'services/exhaustiveGuard/exhaustiveGuard';
import generalMessages from 'translations/common/general.mjs';
import proEOrderMessages from 'translations/specific/pro_ecare_order.mjs';

import CustomTable from '../_tables/CustomTable/CustomTable';
import useStyles from './ECareList.styles';
import { eCareStatusDictionary, ECareStatusT, isECareStatusT } from './ECareStatus';

const ECareList = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const orderStatusOptions = useECareOrderStatus();

  const { query, onSort, page, onFilter, onExactPage, filters, pagesCount, sort } = useTableData<keyof ECareOrder>({
    queryKeyBase: QUERY_KEYS.GET_ECARE_ORDER,
    queryFunction: eCareOrder.getAllOrders(),
  });
  const { isLoading, data } = query;

  const getStatus = (status: ECareStatusT) => (isECareStatusT(status) ? t(eCareStatusDictionary[status]) : t(generalMessages.generalError));

  const columnHelper = createColumnHelper<ECareOrder>();
  const columns = [
    columnHelper.accessor('status', {
      header: t(proEOrderMessages.eOrderTableHeaders.status) as string,
      id: 'status',
      cell: info => getStatus(info.getValue()),
      meta: {
        sx: { width: '19ch' },
      },
    }),
    columnHelper.accessor('companyContractId', {
      header: memo(() => (
        <>
          {t(proEOrderMessages.eOrderTableHeaders.alertSystemContract)}
          <Box sx={{ ml: 1 }}>
            <InfoIconWithTooltip content={t(proEOrderMessages.tooltip.alertSystemContract)} />
          </Box>
        </>
      )),
      id: 'company_contract_id',
      cell: info => info.getValue() || t(generalMessages.noData),
      meta: {
        sx: { width: '19ch' },
      },
    }),
    columnHelper.accessor('serviceStartDate', {
      header: t(proEOrderMessages.eOrderTableHeaders.serviceStartDate) as string,
      id: 'service_start_date',
      cell: info => unknownDateToString(info.getValue(), DATE_FORMATS.DISPLAY),
      meta: {
        sx: { width: '14ch' },
      },
    }),
    columnHelper.accessor('serviceEndDate', {
      header: t(proEOrderMessages.eOrderTableHeaders.serviceEndDate) as string,
      id: 'service_end_date',
      cell: info => (info.getValue() ? unknownDateToString(info.getValue() as Date, DATE_FORMATS.DISPLAY) : t(generalMessages.noData)),
      meta: {
        sx: { width: '14ch' },
      },
    }),
    columnHelper.accessor('patient.personalData.firstName', {
      header: t(proEOrderMessages.eOrderTableHeaders.firstName) as string,
      id: 'service_recipient__patient__personal_data__first_name',
      cell: info => info.getValue() || t(generalMessages.noData),
    }),
    columnHelper.accessor('patient.personalData.lastName', {
      header: t(proEOrderMessages.eOrderTableHeaders.lastName) as string,
      id: 'service_recipient__patient__personal_data__last_name',
      cell: info => info.getValue() || t(generalMessages.noData),
    }),
    columnHelper.accessor('patient.pesel', {
      header: t(proEOrderMessages.eOrderTableHeaders.pesel) as string,
      id: 'service_recipient__patient__pesel',
      cell: info =>
        info.row.original.patient
          ? `${info.getValue()}, ${getAgeFromDOB(info.row.original.patient.personalData.dateOfBirth)}l`
          : t(generalMessages.noData),
      meta: {
        sx: { width: '20ch' },
      },
    }),
    columnHelper.accessor('patient.projectUserNumber', {
      header: t(proEOrderMessages.eOrderTableHeaders.projectUserNumber) as string,
      id: 'service_recipient__patient__project_user_number',
      cell: info => info.getValue() || t(generalMessages.noData),
      meta: {
        sx: { width: '10ch' },
      },
    }),
    columnHelper.accessor('emergencyContactPhone', {
      header: t(proEOrderMessages.eOrderTableHeaders.emergencyContactPhone) as string,
      id: 'emergency_contact_phone',
      cell: info => info.getValue() || t(generalMessages.noData),
      meta: {
        sx: { width: '18ch' },
      },
    }),
    columnHelper.accessor('payers', {
      header: t(proEOrderMessages.eOrderTableHeaders.payer) as string,
      id: 'payers',
      cell: info => info.getValue()?.filter(Boolean).join(', ') || t(generalMessages.noData),
      meta: {
        sx: { width: '15ch' },
      },
    }),
    columnHelper.accessor('subscriptions', {
      header: t(proEOrderMessages.eOrderTableHeaders.subscription) as string,
      id: 'subscriptions',
      cell: memo((info: any) => {
        const subs = info.getValue().filter(Boolean).join(', ');
        if (!subs) return t(generalMessages.noData);
        return (
          <Box className={classes.textWrapWithTooltip}>
            <span className={classes.textEllipsis}>{subs}</span>
            <InfoIconWithTooltip content={subs} />
          </Box>
        );
      }),
    }),
    columnHelper.accessor('id', {
      header: t(proEOrderMessages.eOrderTableHeaders.id) as string,
      id: 'id',
      cell: info => info.getValue(),
      meta: {
        sx: { width: '38ch' },
      },
    }),
    columnHelper.display({
      id: 'detailsView',
      enableSorting: false,
      cell: memo((info: any) => (
        <Link
          className={classes.tableLink}
          to={`/${generatePath(PATHS.PROFESSIONAL_ECARE_ORDER_SINGLE, { orderId: `${info.row.original.id}` })}`}
        >
          <DetailIcon />
        </Link>
      )),
      meta: {
        sx: { maxWidth: '5ch' },
      },
    }),
  ];

  const location = useLocation() as { state: { filterBy?: { key: string; value: string } } };

  const availableFilters: AvailableFilter<ECareOrderListFilters>[] = useMemo(
    () => [
      { key: 'status', type: 'dropdown', label: t(proEOrderMessages.filters.status), options: orderStatusOptions },
      { key: 'company_contract_id', type: 'string', label: t(proEOrderMessages.filters.alertSystemContract) },
      {
        key: 'service_start_date',
        type: 'dateRange',
        label: t(proEOrderMessages.filters.serviceStartDate),
        text: {
          start: t(generalMessages.form.dateFrom),
          end: t(generalMessages.form.dateTo),
        },
        hasTooltip: true,
        tooltipText: t(proEOrderMessages.filters.serviceStartDate),
      },
      {
        key: 'service_end_date',
        type: 'dateRange',
        label: t(proEOrderMessages.filters.serviceEndDate),
        text: {
          start: t(generalMessages.form.dateFrom),
          end: t(generalMessages.form.dateTo),
        },
        hasTooltip: true,
        tooltipText: t(proEOrderMessages.filters.serviceEndDate),
      },
      {
        key: 'service_recipient__patient__personal_data__first_name',
        type: 'string',
        label: t(proEOrderMessages.eOrderTableHeaders.firstName),
      },
      {
        key: 'service_recipient__patient__personal_data__last_name',
        type: 'string',
        label: t(proEOrderMessages.eOrderTableHeaders.lastName),
      },
      { key: 'service_recipient__patient__pesel', type: 'string', label: t(proEOrderMessages.filters.pesel) },
      { key: 'service_recipient__patient__project_user_number', type: 'string', label: t(proEOrderMessages.filters.projectUserNumber) },
      { key: 'emergency_contact_phone', type: 'string', label: t(proEOrderMessages.filters.emergencyContactPhone) },
      { key: 'payer', type: 'string', label: t(proEOrderMessages.filters.payer) },
      { key: 'subscription', type: 'string', label: t(proEOrderMessages.filters.subscription) },
      { key: 'id', type: 'string', label: t(proEOrderMessages.filters.id) },
      {
        key: 'service_recipient__patient_id',
        type: 'hidden',
        label: t(proEOrderMessages.filters.patientId),
        initialValue: location.state?.filterBy?.value,
      },
    ],
    [],
  );

  const pageTitle = {
    headerLabel: t(proEOrderMessages.title),
    icon: <ECareOrderListIcon />,
    itemsCount: data?.count || 0,
  };

  const customFilter = {
    availableFilters,
    filters,
    onFilter,
  };

  const rowClassResolver = (status: ECareStatusT) => {
    switch (status) {
      case 'ENDED':
        return classes.completeColor;
      case 'CLARIFICATION_REQUIRED':
        return classes.receivedColor;
      case 'NEW':
        return classes.dropColor;
      case 'IN_PROGRESS':
        return classes.missedColor;
      case 'ACTIVE':
        return classes.activeColor;
      case 'SUSPENDED':
        return classes.suspendedColor;
      default:
        return exhaustiveGuard(status);
    }
  };

  return (
    <div>
      <PageTitleWithFiltersForTables customFilters={customFilter} pageTitle={pageTitle} />
      <Card>
        {/* Generic Component throws error, but idk why, it should work */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <CustomTable<ECareOrder>
          columns={columns}
          data={data?.data}
          page={page}
          pagesCount={pagesCount}
          rowClassResolver={(rowValues: ECareStatusT) => rowClassResolver(rowValues.status)}
          showLoaderCover={isLoading}
          sort={sort}
          onExactPage={onExactPage}
          onSort={onSort}
        />
      </Card>
    </div>
  );
};

export default ECareList;
