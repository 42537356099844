const QUERY_KEYS = {
  ACTIVATE_ACCOUNT: 'Activate account',
  BRAND_INFO: 'Brand info',
  CLAUSES: 'Clauses',
  CLAUSES_LOCATION: 'Clauses location',
  CONNECTED_FIELD_INFO: 'Connected field info',
  CONTACT_FORM_CATEGORIES: 'Contact form categories',
  CONTACT_FORM_POST: 'Contact form post',
  CONSENTS: 'Consents',
  CONSENTS_CREATE: 'Consents create',
  CONSENTS_DECLINE: 'Consents decline',
  CORE_SERVICE_GROUPS: 'Core service groups',
  CORE_SERVICE_TYPES: 'Core service types',
  CORE_SERVICE_TYPE_DETAILS: 'Core service type details',
  CORE_SERVICE_SUBTYPES: 'Core service subtypes',
  CORE_SERVICE: 'Core service',
  REMOVE_SERVICES_PROFILE: 'Remove services profile',
  FAQ: 'Faq',
  GET_SERVICES_PROFILE: 'Get services profile',
  GET_SERVICES_LIST: 'Get services list',
  GET_SINGLE_SERVICES_PROFILE: 'Get single services profile',
  GLOBAL_SEARCH: 'Global search',
  LANGUAGE: 'Language',
  LOCATIONS: 'Locations',
  PATIENT_INFO: 'Patient info',
  PATIENT_POLICIES: 'Patient policies',
  PATIENTS_REFRESH: 'Patients refresh',
  PAYER: 'Payer',
  PAYERS_BY_SERVICE: 'Payers by service',
  REFERRAL_NEEDED: 'Referral needed',
  REPORTS_LIST: 'Reports list',
  SAVE_SERVICES_PROFILE: 'Save services profile',
  SHOULD_SHOW_PASSWORD_CHANGE_REMINDER: 'Should show password change reminder',
  SYMPTOM_CHECKER_TOKEN: 'Symptom checker token',
  TWO_FA: '2FA',
  USER_AVATAR_CREATE: 'Create user avatar',
  USER_AVATAR_DELETE: 'Delete user avatar',
  USER_AVATAR_GET: 'Get user avatar',
  USER_AVATAR_UPDATE: 'Update user avatar',
  USER_DATA: 'User data',
  USER_DATA_REFRESH: 'User data refresh',
  USER_PERMISSIONS: 'User permissions',
  USER_GROUPS: 'User groups',
  GET_SERVICES_FOR_GROUP: 'Get services for group',
  GET_SERVICES_BY_POLICY_ID: 'Get services by policy id',
  GET_SERVICES_SUBTYPES_BY_POLICY_ID: 'Get services subtypes by policy id',
  GET_PATIENT_STATUS_FOR_PRO: 'Get patient status for pro',
  GET_PATIENT_EDITION_HISTORY: 'Get patient edition history',
  GET_ECARE_ORDER: 'Get ecare order',
  GET_ECARE_ORDER_STAUS_FOR_PRO: 'Get ecare order status for pro',
  GET_API_CONTENT_TYPES: 'Get api content types',
  GET_ECARE_ALARMS: 'Get ecare alarms',
  GET_ECARE_ADDRESS_DIRECTION_ALARMS: 'Get ecare address direction alarms',
  GET_ECARE_ALERT_TYPE_STATUS_FOR_PRO: 'Get ecare alarm type for pro',
  GET_ECARE_ALERT_INTERVENTION_REASON_STATUS_FOR_PRO: 'Get ecare intervention reason for pro',
  GET_ECARE_ALERT_INTERVENTION_TYPE_STATUS_FOR_PRO: 'Get ecare intervention type for pro',
  GET_ECARE_ALERT_INTERVENTION_EFFECT_STATUS_FOR_PRO: 'Get ecare intervention effect for pro',
  GET_ECARE_ALERT_CONTENT_TYPE_STAUS_FOR_PRO: 'Get ecare content type for pro',
  GET_ECARE_TECHNICAL_ISSUES: 'Get ecare technical issues',
  GET_ECARE_ALARM_STAUS_FOR_PRO: 'Get alarm status for pro',
  GET_PATIENT_NOTE: 'Get patient note',
  GET_ECARE_DEVICES: 'Get ecare devices',
  GET_ECARE_PHONE_CALL: 'Get ecare phone call',
  GET_ECARE_STATUS_PHONE_CALL: 'Get ecare status phone call',
  GET_ECARE_CALL_TYPE_PHONE_CALL: 'Get ecare call type phone call',
  GET_ECARE_INTERNAL_NOTES: 'Get ecare internal notes',
  GET_ECARE_CUS_COMMENTS: 'Get ecare cus comments',
  GET_ECARE_SUSPENSIONS: 'Get ecare suspensions',
  GET_PATIENT_RELATED_FILES: 'Get patient relate files',
  GET_ECARE_CONTACT_NOTES: 'Get ecare contact notes',
  GET_ECARE_SOS_PC_INTERNAL_NOTES: 'Get ecare sos pc internal notes',
  GET_ECARE_SOS_PARTNER_NOTES: 'Get ecare sos partner notes',
  SYNC_ECARE_WITH_CUS: 'Sync ecare with cus',
  GET_BITRIX_NOTES: 'Get bitrix notes',
  GET_ECARE_DEVICE_EVENTS: 'Get ecare device events',
};

export default QUERY_KEYS;
