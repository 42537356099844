import { useMemo } from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { ECareAlarm } from 'api/eCareAlarms/eCareAlarms.types';
import eCareOrder from 'api/eCareOrder/eCareOrder';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import DefinitionList from 'components/UI/molecules/DefinitionList/DefinitionList';
import { alarmsTypesDictionary } from 'components/UI/organisms/AlarmsList/_dictionaries/alarmsTypesDictionary';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import generalMessages from 'translations/common/general.mjs';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

import InfoIconWithTooltip from '../../../../atoms/InfoIconWithTooltip/InfoIconWithTooltip';

const usePersonalData = (data: ECareAlarm) => {
  const { t } = useTranslation();
  const { showConfirmationDialog } = useConfirmationDialogContext();

  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_ORDER, data?.order], [data?.order]);
  const { data: orderData } = useQuery(queryKey, eCareOrder.getECareOrder(data.order), {
    enabled: !!data?.order,
    refetchOnWindowFocus: false,
  });

  const { patient } = data;
  const getAddress = () =>
    patient?.personalData
      ? `${patient.personalData.mainAddress.streetName} ${patient.personalData.mainAddress.buildingNumber} ${patient.personalData.mainAddress.apartmentNumber} ${patient.personalData.mainAddress.postcode} ${patient.personalData.mainAddress.city}`
      : t(generalMessages.noData);

  const healthProblem = [
    {
      label: t(proECareAlarmMessages.alarmDetails.typeOfDisease),
      value: orderData?.healthConditions.map(item => item.name),
    },
    {
      label: t(proECareAlarmMessages.alarmDetails.healthProblems),
      value: orderData?.healthProblems.map(item => item.name),
    },
    {
      label: t(proECareAlarmMessages.alarmDetails.dysfunctions),
      value: orderData?.dysfunctions.map(item => item.name),
    },
  ];

  const showaAllHealthIssues = () => {
    showConfirmationDialog({
      title: t(proECareAlarmMessages.alarmDetails.allHealthProblems),
      body: <DefinitionList columns={3} definitions={healthProblem} fontSize='small' />,
      infoOnly: true,
      buttonLabels: { onTrue: t(generalMessages.save) },
    });
  };

  const listData = [
    {
      label: t(proECareAlarmMessages.alarmDetails.pesel),
      value: patient?.pesel || t(generalMessages.noData),
    },
    {
      label: t(proECareAlarmMessages.alarmDetails.operator),
      labelElement: <InfoIconWithTooltip content={t(proECareAlarmMessages.bitrix.operator)} wrapperProps={{ sx: { ml: 1 } }} />,
      value: data.operator ? `${data.operator.first_name} ${data.operator.last_name}` : t(generalMessages.noData),
    },
    {
      label: t(proECareAlarmMessages.alarmDetails.sosType),
      value: t(alarmsTypesDictionary[data.sosType]),
    },
    {
      label: t(proECareAlarmMessages.alarmDetails.typeOfDisease),
      value:
        `${orderData?.healthConditions.length} ${t(proECareAlarmMessages.alarmDetails.multipleTypeOfDisease)}` || t(generalMessages.noData),
    },
    {
      label: t(proECareAlarmMessages.alarmDetails.healthProblems),
      value:
        `${orderData?.healthProblems.length} ${t(proECareAlarmMessages.alarmDetails.multipleHealthProblems)}` || t(generalMessages.noData),
    },
    {
      label: t(proECareAlarmMessages.alarmDetails.dysfunctions),
      value: `${orderData?.dysfunctions.length} ${t(proECareAlarmMessages.alarmDetails.multipleDysfunctions)}` || t(generalMessages.noData),
    },
    {
      label: '',
      value: (
        <Button size='small' variant='outlined' onClick={showaAllHealthIssues}>
          {t(proECareAlarmMessages.alarmCard.buttons.showAllIllnesses)}
        </Button>
      ),
    },

    {
      label: t(proECareAlarmMessages.alarmDetails.address),
      value: getAddress(),
    },
    {
      label: t(proECareAlarmMessages.alarmDetails.degreeOfCognitiveImpairment),
      value: orderData?.cognitiveDisabilityDegree || t(generalMessages.noData),
    },
    {
      label: t(proECareAlarmMessages.alarmDetails.degreeOfMobilityDisability),
      value: orderData?.mobilityDisabilityDegree || t(generalMessages.noData),
    },
    {
      label: t(proECareAlarmMessages.alarmDetails.lifeStyle),
      value: orderData?.lifestyle || t(generalMessages.noData),
    },
    {
      label: t(proECareAlarmMessages.alarmDetails.projectUserNumber),
      value: patient?.projectUserNumber || t(generalMessages.noData),
    },
  ];

  return listData;
};

export default usePersonalData;
