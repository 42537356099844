import { ECareContactNote, ECareContactNoteDTO } from './eCareContactNotes.types';

export const parseSingleECareContactNoteForFE = (eCareContactNoteDTO: ECareContactNoteDTO): ECareContactNote => ({
  id: eCareContactNoteDTO.id,
  createdAt: new Date(eCareContactNoteDTO.created_at),
  updatedAt: new Date(eCareContactNoteDTO.created_at),
  content: eCareContactNoteDTO.content,
  data: new Date(eCareContactNoteDTO.created_at),
  order: eCareContactNoteDTO.order,
  serviceRecipient: eCareContactNoteDTO.service_recipient,
});

export const parseECareContactNotesListForFE = (eCareContactNoteDTO: ECareContactNoteDTO[]): ECareContactNote[] =>
  eCareContactNoteDTO.map(parseSingleECareContactNoteForFE);
